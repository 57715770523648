.form {
  display: grid;
  gap: 10px;
  min-width: 250px;
}

.links {
  display: flex;
  justify-content: space-between;
}

.links a:first-child {
  margin-right: 10px;
}

.links a {
  font-size: 14px;
  text-decoration: none;
}
