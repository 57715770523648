.wrapper {
  background: var(--color-palette-primary);
  color: var(--color-background);
  padding-right: 30px;
  display: flex;
}

.content {
  width: 100%;
  max-width: 1364px;
  margin: 39px auto 39px 159px;
  display: flex;
  flex-wrap: wrap;
}

.legal {
  flex: 1 1 250px;
  padding: 0 15px 15px 0;
}

.copyright {
  flex-basis: 100%;
  grid-column: 1/4;
  grid-row: 2;
  margin: 0;
  text-align: center;
  font-size: 17px;
}

.smallTitle {
  margin: 0 0 15px;
  font-size: 15px;
  font-weight: normal;
}

.link {
  font-size: 20px;
  color: var(--color-background);
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
}

.contacts {
  flex: 1 1 400px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  margin-bottom: auto;
  padding: 0 15px 15px 0;
}

.label {
  display: block;
  flex: 1 1 300px;
  padding: 0 15px 15px 0;
}

.contacts .title {
  grid-column: 1/3;
  flex-basis: 100%;
  margin: 0 0 15px;
}

.contacts .link {
  margin-right: 10px;
  white-space: nowrap;
}

@media (max-width: 1100px) {
  .wrapper {
    padding: 0;
  }

  .content {
    width: 100%;
    margin: 14px 20px;
  }

  .smallTitle {
    font-size: 12px;
  }

  .link,
  .title,
  .label,
  .copyright {
    font-size: 14px;
  }

  .title {
    margin-bottom: 5px;
  }
}

@media (max-width: 440px) {
  .contacts {
    grid-template-columns: 1fr;
  }

  .contacts .title {
    grid-column: 1;
  }
}
