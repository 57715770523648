.button {
  padding: 15px 20px;
  border-radius: var(--round);
  border: 1px solid var(--color-palette-primary);
  cursor: pointer;
  background: var(--color-palette-primary);
  color: var(--color-background);
  font-weight: bold;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.secondary {
  background: transparent;
  color: var(--color-palette-primary);
  border: 1px solid var(--color-palette-primary);
}

.contrast {
  background: var(--color-background);
  color: var(--color-palette-primary);
  border: 1px solid var(--color-background);
}

.button:disabled {
  background: var(--color-palette-ternary);
  cursor: default;
}

.content {
  margin: 0 22px;
}

.icon {
  width: 22px;
  height: 22px;
}

.icon svg {
  width: 100%;
  height: 100%;
}

.small {
  font-size: 14px;
  padding: 5px 10px;
}

@media (max-width: 1100px) {
  .button {
    font-size: 14px;
    padding: 12px;
  }
}
