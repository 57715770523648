.main {
  display: flex;
  padding: 20px 50px;
}

.main .content {
  width: 100%;
  max-width: 850px;
  margin: 0 auto 0 50px;
}

@media (max-width: 1100px) {
  .main {
    padding: 0 0 20px;
  }

  .title {
    margin: 20px;
  }

  .main .content {
    max-width: 100%;
    margin: 0;
  }
}
