.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 24px;
  padding: 0 50px 20px 100px;
}

.wrapper .inner {
  width: 100%;
  max-width: 720px;
  margin: 0;
}

.breadCrumbs {
  width: 100%;
  max-width: 980px;
  margin: 20px 0 0;
}

.title {
  width: 100%;
  max-width: 980px;
  margin: 0 0 20px;
}

.thumbnail {
  width: 100%;
  height: 460px;
  overflow: hidden;
  border-radius: var(--round);
}

.thumbnail img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1100px) {
  .wrapper {
    padding: 20px 0;
  }

  .wrapper .inner {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .thumbnail {
    border-radius: 0;
  }

  .breadCrumbs {
    width: auto;
    margin: 0 20px;
  }

  .title,
  .excerpt,
  .content,
  .date,
  .navigation {
    width: auto;
    margin: 0 20px 20px;
  }
}
