.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper::after,
.wrapper::before {
  content: "";
  flex: 0;
  min-height: 70px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 0 20px;
}

.header {
  width: 100%;
  max-width: 440px;
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
}
