.wrapper {
  display: flex;
  padding: 20px 20px 20px 50px;
  max-width: 1100px;
}

.aside {
  position: relative;
  order: 1;
  flex: 1 0 300px;
}

.nav {
  position: sticky;
  top: 20px;
}

.nav ul {
  list-style: none;
  padding: 0;
}

.nav ul li {
  margin-bottom: 10px;
}

.nav ul li a {
  color: var(--color-text-primary);
  font-weight: bold;
  text-decoration: none;
}

.nav ul li a:hover {
  color: var(--color-palette-primary);
}

.content {
  margin-right: 20px;
}

@media (max-width: 1100px) {
  .wrapper {
    display: block;
  }
}
