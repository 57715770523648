.main {
  display: flex;
  padding: 20px 50px;
}

.main .content {
  width: 100%;
  max-width: 850px;
  margin: 0 auto 0 50px;
}

.skeleton {
  display: inline-block;
  border-radius: 5px;
  background: #e2edf0;
  height: 1.1em;
  position: relative;
  top: 3px;
  width: 10em;
}

.content em {
  opacity: 0.5;
}

@media (max-width: 1100px) {
  .main {
    padding: 0 0 20px;
  }

  .title,
  .intro {
    margin: 20px;
  }

  .main .content {
    max-width: 100%;
    margin: 0;
  }
}
