.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  margin-right: 20px;
}

.inner {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto 0 50px;
}

.views {
  display: flex;
  align-items: center;
  margin: 0;
}

.views span {
  margin-right: 5px;
}

.date {
  margin: 0 10px 0 0;
}

.mainInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1100px) {
  .wrapper {
    margin: 20px;
    padding: 0;
  }
  .inner {
    min-width: 100%;
    margin: 0;
  }
}
