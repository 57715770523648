.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.innerWrapper {
  flex: 1;
  margin-left: 350px;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

@media (max-width: 1100px) {
  .innerWrapper {
    margin-left: 0;
  }
}
