.title {
  color: var(--color-text-primary);
  font-size: 35px;
  line-height: 45px;
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

@media (max-width: 1100px) {
  .title {
    font-size: 22px;
    line-height: 32px;
  }
}
