.list {
  padding: 0;
  list-style: none;
}

.listItem {
  display: flex;
  padding: 10px;
}

.list .listItem:nth-child(2n) {
  background: #e2edf0;
}

.listItemLabel {
  padding: 0 10px 0 0;
  margin: 0 10px 0 0;
  flex: 1;
  border-right: 1px solid var(--color-palette-ternary);
  font-weight: bold;
}

.listItemValue {
  margin: 0;
  flex: 0 0 150px;
}

.placeholder {
  border: 1px solid #e2edf0;
  border-radius: var(--round);
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 0.8em;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1100px) {
  .placeholder {
    margin: 20px;
  }
}
