.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 24px;
  padding: 0 50px 20px 100px;
}

.wrapper .inner {
  width: 100%;
  max-width: 720px;
  margin: 0;
}

.title {
  width: 100%;
  max-width: 980px;
  margin: 20px 0;
}
.thumbnail {
  width: 100%;
  height: 460px;
  overflow: hidden;
  border-radius: var(--round);
}

.thumbnail img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navigation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.navigationRight,
.navigationLeft {
  display: flex;
  color: var(--color-palette-primary);
  font-weight: bold;
  text-decoration: none;
  align-items: center;
  font-size: 17px;
}

.navigationRight {
  grid-column: 2;
}

.navigationLeft {
  grid-column: 1;
}

.navigationRight svg,
.navigationLeft svg {
  flex: 0 0 35px;
  width: 35px;
  height: 35px;
}

.navigationLeft svg {
  margin-right: 10px;
  order: -1;
}

.navigationRight svg {
  margin-left: 10px;
}

@media (max-width: 1100px) {
  .wrapper {
    padding: 20px 0;
  }

  .wrapper .inner {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  .thumbnail {
    border-radius: 0;
  }

  .title,
  .excerpt,
  .content,
  .date,
  .navigation {
    width: auto;
    margin: 0 20px 20px;
  }

  .navigation {
    grid-template-columns: 1fr;
  }
  .navigationLeft,
  .navigationRight {
    grid-column: 1;
  }
}
