.wrapper {
  display: flex;
  padding: 20px 50px;
}
.title {
  margin: 20px 20px 20px 100px;
}

.inner {
  width: 100%;
  max-width: 850px;
  margin: 0 auto 0 50px;
}

@media (max-width: 1100px) {
  .title {
    margin: 20px 20px 0;
  }
  .wrapper {
    padding: 20px;
  }

  .inner {
    max-width: 100%;
    margin: 0;
  }
}
