.wrapper {
  display: flex;
  align-items: center;
}

.spinnerWrapper {
  overflow: hidden;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  color: var(--color-palette-primary);
  animation: spin 1s linear infinite;
}
