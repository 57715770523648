:root {
  --round: 15px;

  --color-background: #fff;
  --color-text-primary: #111;
  --color-border: #ddd;

  --color-palette-primary: #293889;
  --color-palette-secondary: #1d71b8;
  --color-palette-ternary: #079ac1;
  --color-palette-danger: #e30613;

  --color-brand: #e30613;

  --size-content-width: 1280px;
}

html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background);
  color: var(--color-text-primary);
  display: flex;
  flex-direction: column;
  flex: 1;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

[data-rmiz-modal-content] img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
