.loading,
.aloneLink span,
.header {
  width: 100%;
  max-width: 222px;
}

.aloneLink {
  display: flex;
  justify-content: center;
  font-size: 17px;
  font-weight: normal;
  text-decoration: none;
  color: var(--color-text-primary);
  width: 100%;
  font-weight: bold;
}

.aloneLink span {
  padding: 16px 0;
}

.header {
  display: grid;
  grid-template-columns: 38px auto min-content;
  gap: 5px;
  align-items: center;
}

.links {
  margin-top: 10px;
  width: 100%;
  background: var(--color-palette-primary);
}

.links .link {
  color: var(--color-background);
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;
  background: transparent;
  border: none;
  font: inherit;
  text-align: left;
  cursor: pointer;
}

.links .link span {
  display: block;
  margin: 16px 0;
  width: 100%;
  max-width: 222px;
}

.names {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.names .email {
  font-size: 12px;
  opacity: 0.5;
}

.moreButton {
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}
