.wrapper {
  display: flex;
  justify-content: center;
}

.wrapper > *:not(:last-child) {
  margin-right: 5px;
}

.sideButton,
.pageButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  border-radius: var(--round);
  background: var(--color-palette-ternary);
  color: var(--color-background);
  font-weight: bold;
}

.sideButton:not(:disabled),
.pageButton:not(:disabled) {
  cursor: pointer;
  background: var(--color-palette-primary);
}

.sideButton .icon {
  width: 10px;
}
