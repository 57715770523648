.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  background: var(--color-palette-primary);
}

.content {
  width: 100%;
  max-width: 850px;
  margin: 0 auto 0 50px;
}

.link {
  color: var(--color-background);
  text-decoration: none;
  margin-right: 20px;
}

.link.active {
  font-weight: bold;
}

@media (max-width: 1100px) {
  .wrapper {
    padding: 20px;
  }
  .content {
    max-width: 100%;
    margin: 0;
  }
}
