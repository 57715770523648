.menu {
  background-color: var(--color-background);
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 350px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  overflow: auto;
  transform: translateX(0%);
  display: flex;
  flex-direction: column;
  z-index: 2;
  transition: 0.15s ease-out;
}

.menuContainer {
  flex: 1;
  /* background-image: url(./christmas-vertical-pattern.svg);
  background-size: contain;
  background-position: 100% 0;
  background-repeat: no-repeat repeat; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overlap {
  background: rgba(72, 72, 72, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: none;
  opacity: 0;
  transition: opacity 0.1s linear;
  padding: 0;
  border: none;
}

.menu .brand {
  margin-top: 36px;
  width: 158px;
  height: 122px;
}

.navbar {
  margin-top: 41px;
  width: 100%;
}

.account {
  width: 100%;
  padding: 16px 0;
  font-size: 17px;
  max-width: 254px;
}

.mobile {
  display: none;
  justify-content: space-between;
  background: var(--color-background);
  position: sticky;
  top: 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  z-index: 1;
}

.mobile .brand {
  display: block;
  width: 61px;
  height: 51px;
}

.mobile .button {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-palette-primary);
}

.label {
  margin: 16px 0;
  width: 100%;
  max-width: 254px;
  font-size: 12px;
}

.label p {
  margin: 0 16px;
}

.disclaimer {
  width: 100%;
  max-width: 254px;
  margin-bottom: 20px;
}

.disclaimer img {
  width: 50px;
  margin: 0 16px;
}

@media (max-width: 1100px) {
  .menu {
    transform: translateX(-100%);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0);
  }

  .menu.opened {
    transform: translateX(0%);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  }

  .overlap.opened {
    display: block;
    opacity: 1;
  }

  .mobile {
    display: flex;
  }
}
