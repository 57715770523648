.wrapper {
  display: flex;
  flex-direction: column;
}

.link {
  display: flex;
  justify-content: center;
  padding: 16px;
  font-size: 17px;
  font-weight: normal;
  text-decoration: none;
  color: var(--color-text-primary);
}

.linkContent {
  width: 100%;
  max-width: 222px;
}

.linkContentIcon {
  position: relative;
  top: 0.2em;
  width: 1em;
  height: auto;
}

.link.selected {
  background: var(--color-palette-primary);
  color: var(--color-background);
}
