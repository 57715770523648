.card {
  margin-bottom: 20px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.button {
  margin: auto;
}
