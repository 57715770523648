.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  margin-right: 20px;
}

.inner {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto 0 50px;
}

.documentsItem {
  color: var(--color-palette-primary);
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  margin-bottom: 20px;
}
.documentsItem .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: auto;
  margin-right: 20px;
  flex: 0 0 54px;
}

.documentsItem p {
  margin: 10px 0 0;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.button {
  margin: 20px auto;
}

.spinner {
  display: flex;
  justify-content: center;
}

.image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: var(--round);
  margin-bottom: 10px;
}

.image > * {
  width: 100%;
  height: 100%;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.date {
  margin: 0 0 10px;
  font-size: 14px;
}

.title {
  font-size: 20px;
  margin: 0;
}

@media (max-width: 1100px) {
  .date {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .title {
    font-size: 14px;
  }

  .header {
    top: 71px;
  }

  .documentsItem {
    font-size: 14px;
  }

  .wrapper {
    margin: 20px;
    padding: 0;
  }
  .inner {
    min-width: 100%;
    margin: 0;
  }
}
