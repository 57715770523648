.wrapper {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrapper > * {
  white-space: nowrap;
  color: var(--color-text-primary);
}

.separator {
  margin: 0 5px;
}
