.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 24px;
  padding: 0 50px 20px 100px;
}

.inner {
  width: 100%;
  max-width: 920px;
  margin: 40px 0;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.name,
.story,
.map {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.story {
  z-index: 0;
  position: relative;
}

.name .label,
.story .label,
.map .label {
  margin: 0 0 10px;
}

.thumbnail {
  display: block;
  margin-bottom: 20px;
}

.actions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  width: 100%;
  margin: 0 auto;
  gap: 20px;
  max-width: 600px;
}

@media (max-width: 1100px) {
  .header {
    top: 71px;
  }

  .wrapper {
    padding: 20px;
    font-size: 14px;
  }
  .inner {
    min-width: 100%;
    margin: 0;
  }
}
