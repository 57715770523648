.title,
.contentWrapper {
  display: flex;
  padding: 20px 50px;
}

.title span,
.contentWrapper .stories {
  width: 100%;
  max-width: 850px;
  margin: 0 auto 0 50px;
}

.center {
  margin: 0 auto;
}

.stories .story {
  display: block;
  margin-bottom: 20px;
  text-decoration: none;
}

@media (max-width: 1100px) {
  .title {
    padding: 20px;
    margin: 0;
  }
  .contentWrapper {
    margin: 20px 0 0;
    padding: 0;
  }

  .title span,
  .contentWrapper .stories {
    max-width: 100%;
    margin: 0;
  }
}
