.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}

.title,
.list {
  width: 100%;
  max-width: 850px;
  margin: 0 auto 0 50px;
}

.title {
  margin-bottom: 20px;
}

.story {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  text-decoration: none;
  color: var(--color-text-primary);
}

.thumbnail {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: var(--round);
  height: 256px;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.story h3 {
  margin: 0 0 10px;
}

.button {
  margin: 0 auto;
}

@media (max-width: 1100px) {
  .wrapper {
    padding: 20px 0;
  }

  .title {
    margin: 0 20px 20px;
  }

  .list {
    max-width: 100%;
    margin: 0;
  }

  .story {
    grid-template-columns: 1fr;
  }

  .content {
    padding: 0 20px;
  }

  .thumbnail {
    border-radius: 0;
  }
}
