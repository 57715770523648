.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.viewport {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.controls {
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: min-content auto 147px min-content;
  gap: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 26px);
  background: linear-gradient(rgba(1, 1, 1, 0) 0%, rgba(1, 1, 1, 1) 100%);
  color: var(--color-background);
  padding: 30px 13px 17px;
  align-items: center;
  z-index: 1;
  transition: 0.1s ease-out;
}

.controls.playing {
  opacity: 0;
}

.wrapper.showControlsOnPlaying .controls.playing {
  opacity: 1;
}

.progress {
  grid-row: 1;
  grid-column: 1/5;
  cursor: pointer;
}

.duration {
  grid-row: 2;
  grid-column: 2;
  color: rgba(255, 255, 255, 0.4);
}

.durationCurrent {
  color: rgba(255, 255, 255, 1);
}

.play {
  grid-column: 1;
  grid-row: 2;
  color: #fff;
  background: transparent;
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 17px;
}

.play svg {
  width: 20px;
  height: 20px;
}

.volume {
  grid-row: 2;
  grid-column: 3;
  display: flex;
  align-items: center;
}

.volumeIcon {
  height: 22px;
  width: 29px;
}

.volumeButton {
  color: #fff;
  padding: 0;
  margin-right: 6px;
  background: transparent;
  border: none;
  height: 22px;
}

.volume .volumeRange {
  flex: 1;
  height: 4px;
  cursor: pointer;
}

.fullscreen {
  grid-row: 2;
  grid-column: 4;
  background: transparent;
  padding: 0;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0 17px;
}

.fullscreen svg {
  width: 20px;
  height: 20px;
}

.range {
  position: relative;
  height: 5px;
  background: rgba(255, 255, 255, 0.2);
}

.rangeItem {
  position: absolute;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
}

.rangeItem.strong {
  background: #fff;
}

.rangeBar {
  position: absolute;
  width: calc(100% - 20px);
  left: 10px;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.rangePoint {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 0;
}

.rangePoint::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading svg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: calc(4em + 3vw + 2vh);
  height: calc(4em + 3vw + 2vh);
  animation: spin 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
}

@media (max-width: 800px) {
  .controls {
    grid-template-columns: min-content auto 40% min-content;
  }
  .duration {
    display: none;
  }
}
