.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  text-decoration: none;
  color: var(--color-text-primary);
}

.wrapper:hover h3 {
  text-decoration: underline;
}

.thumbnail {
  width: 100%;
  overflow: hidden;
  border-radius: var(--round);
  height: 256px;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
}

.content h3 {
  font-size: 20px;
  margin: 0 0 14px;
}

.content .date {
  font-size: 17px;
  margin: 0 0 10px;
}

.content .excerpt {
  font-size: 20px;
  margin: 0;
}

@media (max-width: 1100px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .thumbnail {
    border-radius: 0px;
  }

  .content {
    padding: 20px;
  }
}
