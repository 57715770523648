.header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 24px;
  padding: 0 50px 20px 100px;
}

.inner {
  width: 100%;
  max-width: 920px;
  margin: 0;
}

.remove {
  border-color: var(--color-palette-danger);
  color: var(--color-palette-danger);
  margin-right: auto;
}

.story {
  margin-bottom: 20px;
}

.story:hover h3 {
  text-decoration: none;
}

.areYouSure {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.areYouSure .label {
  flex: 1 1 100%;
  margin-bottom: 10px;
}

.areYouSureButton {
  margin-right: 10px;
}

.gray {
  opacity: 0.5;
  font-weight: normal;
}

.button {
  margin: 0 auto;
}

@media (max-width: 1100px) {
  .header {
    top: 71px;
  }

  .title {
    margin: 20px;
  }
  .wrapper {
    padding: 0px;
    font-size: 14px;
  }
  .inner {
    min-width: 100%;
    margin: 0;
  }
}
