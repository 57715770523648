.wrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  margin-right: 20px;
}

.inner {
  width: 100%;
  max-width: 850px;
  margin: 0 auto 0 50px;
}

.documentsItem {
  color: var(--color-palette-primary);
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  margin-bottom: 20px;
}
.documentsItem .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: auto;
  margin-right: 20px;
  flex: 0 0 54px;
}

.documentsItem p {
  margin: 10px 0 0;
}

@media (max-width: 1100px) {
  .documentsItem {
    font-size: 14px;
  }

  .wrapper {
    margin: 20px;
    padding: 0;
  }
  .inner {
    min-width: 100%;
    margin: 0;
  }
}
