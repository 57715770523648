.wrapper {
  position: relative;
  background: var(--color-palette-primary);
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  font-weight: bold;
  line-height: 1;
  color: #fff;
}
