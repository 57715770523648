.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand {
  width: 158px;
  margin-bottom: 10px;
}

.label {
  font-size: 24px;
  line-height: 1;
  font-weight: bold;
  margin: 0;
}

.title {
  margin: 0 0 50px;
}

.title.withDescription {
  margin: 0 0 20px;
}

.description {
  margin: 0 0 20px;
  font-size: 20px;
  text-align: left;
  width: 100%;
}

@media (max-width: 1100px) {
  .brand {
    width: 130px;
  }
}
