.header,
.news {
  display: flex;
  padding: 20px 50px;
}

.header .content,
.news .content {
  width: 100%;
  max-width: 850px;
  margin: 0 auto 0 50px;
}

.header .label {
  text-transform: uppercase;
  font-size: 14px;
  margin: 0 0 20px;
}

.header .title {
  margin: 0 0 20px;
}

.header .description {
  font-size: 14px;
  margin: 0;
}

.news .title {
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: bold;
  color: var(--color-palette-primary);
  padding-bottom: 2px;
  margin: 0 0 30px;
}

.news .title::after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: var(--color-palette-primary);
}

.news .button {
  margin: 0 auto 20px;
}

@media (max-width: 1100px) {
  .header {
    padding: 20px;
  }

  .header .content,
  .news .content {
    max-width: 100%;
    margin: 0;
  }

  .header .label,
  .header .description {
    font-size: 12px;
    margin-bottom: 0;
  }

  .header .title {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .news {
    padding: 20px 0;
  }

  .news .title {
    margin: 0 20px 16px;
  }
}
