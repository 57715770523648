.input {
  position: relative;
  padding: 20px 25px;
  border-radius: var(--round);
}

@media (max-width: 1100px) {
  .input {
    padding: 10px 15px;
  }
}
