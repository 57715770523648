.wrapper {
  padding: 20px;
  border: 1px solid var(--color-border);
}

.title {
  font-weight: bold;
  margin: 0 0 10px;
}

.form {
  display: flex;
  align-items: center;
}

.amount {
  display: block;
  flex: 0 1;
  /* border: none; */
  max-width: 70px;
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 5px;
  text-align: right;
}

.currency {
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 10px;
}

.send {
  background: var(--color-palette-primary);
  color: var(--color-background);
  border: none;
  padding: 10px 20px;
}

@media (max-width: 1100px) {
  .wrapper {
    margin: 20px;
  }
}
