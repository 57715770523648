.wrapper {
  position: relative;
  background: var(--color-palette-primary);
  color: var(--color-background);
  padding: 20px;
  display: grid;
  grid-template-columns: auto 120px;
  grid-template-rows: auto 1fr;
  gap: 8px;
  overflow: hidden;
}

.bells {
  position: absolute;
  width: auto;
  height: 120%;
  max-height: 230px;
  left: 20px;
  top: -25%;
  opacity: 0.4;
  pointer-events: none;
}

.title {
  grid-column: 1;
  margin: 0;
  z-index: 1;
}

.callToAction {
  grid-column: 1;
  margin: 0;
  z-index: 1;
}

.callToAction.mobile {
  display: none;
}

.qr {
  fill: var(--color-palette-primary);
  background: var(--color-background);
  grid-column: 2;
  grid-row: 1/3;
  z-index: 1;
}

.button {
  display: none;
  grid-column: 2;
  grid-row: 1/3;
  align-self: center;
  z-index: 1;
}

.button svg {
  width: 100%;
  height: auto;
}

@media (max-width: 1100px) {
  .callToAction {
    display: none;
  }

  .callToAction.mobile {
    display: block;
  }

  .qr {
    display: none;
  }

  .button {
    display: block;
  }
}
